import { map } from 'lodash'
import qs from 'qs'
import CmsService from '../cms.service'
import { CarouselType } from '../../type/CarouselType'
import { formatKeys } from '../../utils/filters'

class CarouselService extends CmsService {
    public getCarousels() {
        return new Promise<Array<CarouselType>>((resolve, reject) => {
            const currentTime = new Date().toISOString()
            const queryParams = qs.stringify({
                _sort: 'sort_order:DESC,id:DESC',
                _where: {
                    _or: [
                        {
                            _and: [
                                { start_at_lte: currentTime },
                                { end_at_gte: currentTime },
                            ],
                        },
                        { start_at_null: true },
                        { end_at_null: true },
                    ],
                },
            })
            this.client
                .get(`carousels?${queryParams}`)
                .then((response) => {
                    const data = map(response.data, (item) => {
                        return formatKeys(item)
                    })
                    resolve(data as CarouselType[])
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new CarouselService()
