import { setCookie } from 'typescript-cookie'
import CmsService from './cms.service'
import { UserType } from '../type/UserType'
import { PIALA_AUTH_TOKEN } from '../utils/constant'

class UserService extends CmsService {
    public getProfile() {
        return new Promise<UserType>((resolve, reject) => {
            this.client
                .get('users/me')
                .then((response) => {
                    setCookie(PIALA_AUTH_TOKEN, response.data?.pialaAuthToken, {
                        sameSite: 'lax',
                        secure: true,
                        expires: new Date(
                            new Date().getTime() + 24 * 60 * 60 * 1000
                        ),
                        path: '/',
                    })
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new UserService()
