import { combineReducers } from 'redux'
import artistReducers from './artist.reducer'
import userReducers from './user.reducer'
import appReducers from './app.reducer'
import blogReducers from './blog.reducer'
import faqReducers from './faq.reducer'
import groupReducers from './group.reducer'
import ProductReducers from './product.reducer'
import ThemeReducer from './theme.reducer'
import newsReducers from './news.reducer'
import gachaReducers from './gacha.reducer'
import eventReducers from './event.reducer'
import globalLoadingReducers from './global-loading.reducer'

const rootReducer = combineReducers({
    app: appReducers,
    artist: artistReducers,
    user: userReducers,
    blog: blogReducers,
    faq: faqReducers,
    artistGroup: groupReducers,
    product: ProductReducers,
    theme: ThemeReducer,
    news: newsReducers,
    gacha: gachaReducers,
    event: eventReducers,
    globalLoading: globalLoadingReducers,
})

export default rootReducer
