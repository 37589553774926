/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

type Props<T extends FieldValues> = PropsWithChildren<{
    className?: string
    onError?: () => void
    onSubmit: (data: T) => void
    useFormMethod: UseFormReturn<T, any, undefined>
    children: React.ReactNode
}>

export default function Form<T extends FieldValues>(
    props: Props<T>
): JSX.Element {
    const { onError, onSubmit, useFormMethod, children, ...rest } = props

    const { handleSubmit } = useFormMethod

    return (
        <FormProvider {...useFormMethod}>
            <form onSubmit={handleSubmit(onSubmit, onError)} {...rest}>
                {children}
            </form>
        </FormProvider>
    )
}
