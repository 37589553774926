import React from 'react'
import { Modal, ModalProps } from 'antd'

type Props = {
    children: React.ReactNode
    className?: string
} & ModalProps

const BaseModal = (props: Props): JSX.Element => {
    const { children, className, ...rest } = props

    return (
        <Modal className={`base-modal ${className}`} {...rest}>
            {children}
        </Modal>
    )
}

BaseModal.defaultProps = {
    className: null,
}

export default BaseModal
