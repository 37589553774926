import { call, put, takeLatest, fork, all, select } from 'redux-saga/effects'
import ArtistService from '../../services/portal/artist.service'
import ArtistCmsService from '../../services/artist/artistCms.service'
import { ArtistCategoryType, ArtistType } from '../../type/ArtistType'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import { ArtistActions, ArtistActionTypes } from '../constant/artist.const'
import { rootState } from '../index'
import { QueryParams } from '../../type/QueryParams'
import { FavoriteType } from '../../type/FavoriteType'
import { Pagination } from '../../type/Pagination'
import ProductService, {
    GetGroupProductListUrlQueryParams,
    ProductQueryParams,
} from '../../services/product/index'
import { ProductType } from '../../type/ProductType'

/**
 * Get list of categories from ec cube
 */
export function* getCategories() {
    try {
        const categories: Array<ArtistCategoryType> = yield call(
            ArtistService.getCategories.bind(ArtistService)
        )
        yield put({
            type: ArtistActionTypes.setCategories,
            payload: categories,
        } as ArtistActions)
    } catch (error) {
        console.error(error)
    }
}

/**
 * Get list artist from ec cube
 */
export function* getListArtists(actions: ArtistActions) {
    try {
        const activeCategoryId: string = yield select(
            (state: rootState) => state.artist.categoryActive
        )
        const currentSearchString: string = yield select(
            (state: rootState) => state.artist.stringSearched
        )

        yield put({
            type: ArtistActionTypes.setLoading,
            payload: true,
        } as ArtistActions)

        const artists: Array<ArtistType> = yield call(
            ArtistService.getList.bind(ArtistService),
            {
                id: activeCategoryId,
                name: currentSearchString,
            } as QueryParams
        )
        // Reset list artist if the users searched
        if (currentSearchString !== '') {
            yield put({
                type: ArtistActionTypes.resetArtistListInfo,
            })
        }
        yield put({
            type: ArtistActionTypes.setList,
            payload: artists,
        } as ArtistActions)

        // Set loading is false
        yield put({
            type: ArtistActionTypes.setLoading,
            payload: false,
        } as ArtistActions)

        // Trigger action getListArtistsByIds
        if (actions.payload.isListInfoNeeded) {
            yield put({ type: ArtistActionTypes.fetchListArtistInfo })
        }
    } catch (error) {
        // TODO: Handle the error
        console.error(error)
    }
}

/**
 * Fetching the information of artists from CMS
 * @param actions
 */
export function* getArtistInfoFromCMS(actions: ArtistActions) {
    // Set loading is true
    yield put({
        type: ArtistActionTypes.setLoading,
        payload: true,
    } as ArtistActions)

    try {
        const pagination: Pagination = yield select(
            (state: rootState) => state.artist.pagination
        )
        const artists: Array<ArtistInfoType> = yield call(
            ArtistCmsService.getArtists.bind(ArtistCmsService),
            actions.payload.artistIds,
            {
                _limit: pagination.limit,
                _start: pagination.start,
            }
        )
        yield put({
            type: ArtistActionTypes.setListArtistInfo,
            payload: artists,
        } as ArtistActions)
    } catch (e) {
        yield put({
            type: ArtistActionTypes.setListArtistInfo,
            payload: [],
        } as ArtistActions)
        console.error(e)
    }

    // Set loading is false
    yield put({
        type: ArtistActionTypes.setLoading,
        payload: false,
    } as ArtistActions)
}

export function* getArtistLiveTickets(artistId: number | string) {
    try {
        if (artistId) {
            const liveTickets: Array<ProductType> = yield call(
                ProductService.getProducts.bind(ProductService),
                {
                    sale_type: '6',
                    recent_flg: '1',
                    on_sale_flg: '1',
                    sort_column: 'venue_start_date',
                    sort_way: '0',
                    artist_id: artistId,
                } as ProductQueryParams
            )
            yield put({
                type: ArtistActionTypes.setArtistLiveTickets,
                payload: liveTickets,
            })
        } else {
            yield put({
                type: ArtistActionTypes.setArtistLiveTickets,
                payload: [],
            })
        }
    } catch (error) {
        // yield put({
        //     type: ArtistActionTypes.setE,
        //     payload: error,
        // })
        console.log('error', error)
    }
}

/**
 * Get full information of artists from CMS
 */
export function* getListArtistsByIds() {
    // Raw artist list, which was fetched from Ec Cube
    const rawList: Array<ArtistType> = yield select(
        (state: rootState) => state.artist.list
    )
    if (rawList.length > 0) {
        // Convert list to array of ids
        const ids = rawList.map((artist) => {
            return artist.id
        })

        // Fetch it
        yield put({
            type: ArtistActionTypes.fetchArtistInfo,
            payload: { artistIds: ids },
        })
    } else {
        // In case the raw list is empty
        // set the artist list is null
        yield put({
            type: ArtistActionTypes.setListArtistInfo,
            payload: [],
        } as ArtistActions)
    }
}

/**
 * Get favorite artists information by id
 */
export function* getFavoritesArtistByIds() {
    // Raw list from ec cube
    const rawList: FavoriteType[] = yield select(
        (state: rootState) => state.artist.favorites
    )

    if (rawList.length > 0) {
        // Get artist ids
        const ids = rawList.map((favorite) => {
            return favorite.artistId
        })

        yield put({
            type: ArtistActionTypes.fetchArtistInfo,
            payload: { artistIds: ids },
        })
    } else {
        // In case the raw list is empty
        // set the artist list is null
        yield put({
            type: ArtistActionTypes.setListArtistInfo,
            payload: [],
        } as ArtistActions)
    }
}

/**
 * Get favorite list from eccube
 * @param actions
 */
export function* getFavoritesList(actions: ArtistActions) {
    // Set loading
    yield put({
        type: ArtistActionTypes.setFetchingFavorites,
        payload: true,
    })

    try {
        const activeCategoryId: string = yield select(
            (state: rootState) => state.artist.categoryActive
        )
        const favorites: FavoriteType[] = yield call(
            ArtistService.getFavorites.bind(ArtistService),
            actions.payload?.customerId ?? null,
            actions.payload?.artistId ?? null,
            !actions.payload?.getAll && activeCategoryId
                ? parseInt(activeCategoryId, 10)
                : null
        )
        yield put({
            type: ArtistActionTypes.setFavorites,
            payload: favorites,
        })
        yield put({ type: ArtistActionTypes.fetchFavoritesByIds })
    } catch (e) {
        yield put({
            type: ArtistActionTypes.setFavorites,
            payload: [],
        })
        // TODO: Handle the API call's errors
        console.error(e)
    }

    // Set loading
    yield put({
        type: ArtistActionTypes.setFetchingFavorites,
        payload: false,
    })
}

/**
 * Add favorite artist & store the data to ECcube via API
 * @param {ArtistActions} actions
 */
export function* addFavoriteArtist(actions: ArtistActions) {
    // get customer id from Redux store
    const customerId: number = yield select(
        (state: rootState) => state.user.me.ecCubeId
    )

    // construct request body and add its key & value
    const params = new URLSearchParams()
    params.append('customer_id', customerId.toString())
    params.append('artist_id', actions.payload.artistId)

    try {
        // call API to add favorite artist and get the feedback about the response
        const feedback: string = yield call(
            ArtistService.addFavoriteArtist.bind(ArtistService),
            params
        )

        // set the feedback in the Redux Store
        yield put({
            type: ArtistActionTypes.setMsgAfterProcessFavorite,
            payload: feedback,
        })

        // fetch the latest favorite list
        yield put({
            type: ArtistActionTypes.fetchFavorites,
            payload: {
                customerId,
                artistId: actions.payload.artistId,
            },
        })

        return true
    } catch (error) {
        // set the error message in the Redux Store
        yield put({
            type: ArtistActionTypes.setMsgAfterProcessFavorite,
            payload:
                'システムエラーが発生しました。 大変お手数ですが、サイト管理者までご連絡ください。',
        })

        return false
    }
}

/**
 * Delete favorite artist & update the favorite list
 * @param {ArtistActions} actions
 */
export function* deleteFavoriteArtist(actions: ArtistActions) {
    // get customer id from Redux store
    const customerId: number = yield select(
        (state: rootState) => state.user.me.ecCubeId
    )

    // construct request body and add its key & value
    const params = new URLSearchParams()
    params.append('customer_id', customerId.toString())
    params.append('artist_id', actions.payload.artistId)

    try {
        // call API to delete favorite artist and get the feedback about the response
        const feedback: string = yield call(
            ArtistService.deleteFavoriteArtist.bind(ArtistService),
            params
        )
        // set the feedback in the Redux Store
        yield put({
            type: ArtistActionTypes.setMsgAfterProcessFavorite,
            payload: feedback,
        })
        yield put({
            type: ArtistActionTypes.resetArtistListInfo,
        })
        // fetch the latest favorite list
        yield put({
            type: ArtistActionTypes.fetchFavorites,
            payload: {
                customerId,
            },
        })
    } catch (error) {
        // dispatch action to store the error message to show the user
        yield put({
            type: ArtistActionTypes.setMsgAfterProcessFavorite,
            payload:
                'システムエラーが発生しました。 大変お手数ですが、サイト管理者までご連絡ください。',
        })
    }
}

/**
 * Fetch raw artist list for live artist list
 */
export function* getRawList() {
    try {
        // fetch raw artist list from eccube
        const rawList: Array<ArtistType> = yield call(
            ArtistService.getList.bind(ArtistService)
        )
        yield put({
            type: ArtistActionTypes.setListRaw,
            payload: rawList,
        } as ArtistActions)

        yield put({ type: ArtistActionTypes.fetchListArtistInfoRawByIds })
    } catch (error) {
        console.error(error)
    }
}

/**
 * fetch artist info of all artists in list from cms
 * if any artist data is fetched

 */

export function* getRawListInfo() {
    const rawList: ArtistType[] = yield select(
        (state: rootState) => state.artist.listRaw
    )

    try {
        if (rawList.length > 0) {
            const ids = rawList.map(({ id }) => {
                return id
            })
            const rawListInfo: Array<ArtistInfoType> = yield call(
                ArtistCmsService.getArtists.bind(ArtistCmsService),
                ids,
                {
                    _limit: -1,
                }
            )
            yield put({
                type: ArtistActionTypes.setListArtistInfoRaw,
                payload: rawListInfo,
            } as ArtistActions)
        } else {
            yield put({
                type: ArtistActionTypes.setListArtistInfoRaw,
                payload: [],
            } as ArtistActions)
        }
    } catch (error) {
        console.error(error)
    }
}

/**
 * Artist Saga root
 */
export function* artistSaga() {
    yield all([
        fork(getCategories),
        takeLatest(ArtistActionTypes.fetchList, getListArtists),
        takeLatest(ArtistActionTypes.fetchListArtistInfo, getListArtistsByIds),
        takeLatest(ArtistActionTypes.addFavoriteArtist, addFavoriteArtist),
        takeLatest(ArtistActionTypes.fetchFavorites, getFavoritesList),
        takeLatest(ArtistActionTypes.fetchArtistInfo, getArtistInfoFromCMS),
        takeLatest(
            ArtistActionTypes.fetchFavoritesByIds,
            getFavoritesArtistByIds
        ),
        takeLatest(
            ArtistActionTypes.requestDeleteFavorite,
            deleteFavoriteArtist
        ),
        fork(getRawList),
        takeLatest(
            ArtistActionTypes.fetchListArtistInfoRawByIds,
            getRawListInfo
        ),
    ])
}
