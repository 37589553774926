import React, { MouseEventHandler, useState } from 'react'
import { Drawer } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HeaderMenuOverlay from './HeaderMenuOverlay'

const HeaderMenu = (): JSX.Element => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const handleVisibleChange: () => MouseEventHandler | void = () => {
        setIsDropdownVisible(!isDropdownVisible)
    }
    const onClose = () => setIsDropdownVisible(false)
    return (
        <div>
            <button
                className="portal-groups__header__side-menu-menu__button"
                type="button"
                onClick={handleVisibleChange}
            >
                <FontAwesomeIcon
                    icon={['fas', 'bars']}
                    className="portal-groups__header__side-menu-menu__button__icon"
                />
            </button>
            <Drawer
                title={<div className="ml-16">Menu</div>}
                placement="right"
                onClose={onClose}
                width={360}
                visible={isDropdownVisible}
            >
                <HeaderMenuOverlay handleVisible={handleVisibleChange} />
            </Drawer>
        </div>
    )
}
export default HeaderMenu
