import { Action } from 'redux'
import { ArtistCategoryType, ArtistType } from '../../type/ArtistType'
import { ArtistInfoType } from '../../type/ArtistInfoType'
import { FavoriteType } from '../../type/FavoriteType'
import { Pagination } from '../../type/Pagination'
import { ProductType } from '../../type/ProductType'

export type ArtistStateType = {
    isLoading: boolean
    list: Array<ArtistType>
    listInfo: Array<ArtistInfoType>
    categories: Array<ArtistCategoryType>
    categoryActive: string
    stringSearched: string
    favorites: FavoriteType[]
    fetchingFavorites: boolean
    msgAfterProcessFavorite: string
    isFavoriteAdded: boolean
    listRaw: Array<ArtistType>
    listInfoRaw: Array<ArtistInfoType>
    pagination: Pagination
    isShowFcOption: boolean
    headerMenuActiveTag: string
    liveTickets: Array<ProductType>
    artistInfo: ArtistInfoType | null
    isLoadingArtistInfo: boolean
}

export interface ArtistActions<T = any> extends Action {
    payload?: T
}

export const ArtistActionTypes = {
    fetchList: 'ARTIST_FETCH_LIST',
    setTotal: 'SET_TOTAL',
    fetchFavorites: 'ARTIST_FETCH_FAVORITES',
    fetchListArtistInfo: 'ARTIST_FETCH_LIST_INFO',
    fetchCategories: 'ARTIST_FETCH_CATEGORIES',
    fetchArtistInfo: 'ARTIST_FETCH_FROM_CMS',
    fetchFavoritesByIds: 'ARTIST_FETCH_FAVORITE_BY_IDS',
    setLoading: 'ARTIST_SET_LOADING',
    setFetchingFavorites: 'ARTIST_SET_FETCHING_FAVORITES',
    setFavorites: 'ARTIST_SET_FAVORITES',
    setList: 'ARTIST_SET_LIST',
    setListArtistInfo: 'ARTIST_SET_LIST_INFO',
    setCategories: 'ARTIST_SET_CATEGORIES',
    setCategoryActive: 'ARTIST_SET_CATEGORY_ACTIVE',
    setStringSearched: 'ARTIST_SET_STRING_SEARCHED',
    addFavoriteArtist: 'ARTIST_ADD_FAVORITE',
    setMsgAfterProcessFavorite: 'ARTIST_SET_MESSAGE_AFTER_PROCESS_FAVORITE',
    requestDeleteFavorite: 'ARTIST_REQUEST_DELETE_FAVORITE',
    checkFavoriteAdded: 'ARTIST_CHECK_FAVORITE_ADDED',
    fetchListRaw: 'ARTIST_FETCH_LIST_RAW',
    setListRaw: 'ARTIST_SET_LIST_RAW',
    fetchListArtistInfoRawByIds: 'ARTIST_FETCH_LIST_ARTIST_INFO_ROW_BY_IDS',
    setListArtistInfoRaw: 'ARTIST_SET_LIST_ARTIST_INFO_RAW',
    setPagination: 'ARTIST_SET_PAGINATION',
    resetArtistListInfo: 'ARTIST_RESET_ARTIST_LIST_INFO',
    setIsShowFcOption: 'SET_IS_SHOW_FC_OPTION',
    setHeaderMenuActiveTag: 'SET_HEADER_MENU_ACTIVE_TAG',
    setArtistLiveTickets: 'ARTIST_SET_LIVE_TICKETS',
    setArtistInfo: 'ARTIST_INFO',
    setIsLoadingArtistInfo: 'IS_LOADING_ARTIST_INFO',
}
