import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import header from '../../assets/images/portal/image_live_ticket/header_title.png'
import HeaderMenu from './HeaderMenu'
import HeaderNavigation from './HeaderNavigation'
import HeaderRankingLink from './HeaderRankingLink'
import { useAppSelector } from '../../store/hooks/AppHooks'
import useGetWidthSize from '../../hook/useGetWidthSize'
import NftService from '../../services/nft.service'
import { checkArtistHiddenItems } from '../../utils/utils'
import ClickableWrapper from '../common/ClickableWrapper'

type Props = {
    headerStyles?: React.CSSProperties
    headerWrapperStyles?: React.CSSProperties
    isClickable?: boolean
}
const Header = (props: Props): JSX.Element => {
    const { headerStyles, headerWrapperStyles, isClickable = true } = props
    const isAuth = useAppSelector((state) => state.user.isAuth)
    const userProfile = useAppSelector((state) => state.user.me)
    const artistInfo = useAppSelector((state) => state.artist.artistInfo)
    const isLoadingArtistInfo = useAppSelector(
        (state) => state.artist.isLoadingArtistInfo
    )
    const isHideLogo = checkArtistHiddenItems(artistInfo, isLoadingArtistInfo)
    const [isDisplayInput] = useState(false)
    const { width } = useGetWidthSize()

    const handleRedirectUrl = async (path = 'mypage') => {
        if (isAuth) {
            const url = await NftService.getRedirectUrl(
                userProfile.pialaAuthToken,
                path
            )
            window.location.href = url
        } else {
            window.location.href = `${process.env.REACT_APP_EC_API_URL}/mypage/login`
        }
    }

    return (
        <header className="portal-groups__header" style={headerStyles}>
            <div
                className="portal-groups__header__wrapper"
                style={headerWrapperStyles}
            >
                {isHideLogo ? null : (
                    <div className="portal-groups__header__fixed-content__container">
                        <div className="portal-groups__header__ranking">
                            <HeaderRankingLink />
                        </div>
                        <div className="portal-groups__logo">
                            <Link to="/portal">
                                <div className="portal-groups__logo__container">
                                    <div className="portal__logo__container">
                                        <img src={header} alt="headerTitle" />
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="portal-groups__header__side-menu-wrapper">
                            <ClickableWrapper isClickable={isClickable}>
                                {width < 768 && <HeaderMenu />}
                            </ClickableWrapper>
                        </div>
                    </div>
                )}

                <div className="portal-groups__header__navigation">
                    {isDisplayInput && <HeaderNavigation />}
                    <div
                        className="portal__header__menu__pc"
                        style={{ height: isHideLogo ? '70px' : 'initial' }}
                    >
                        <ClickableWrapper isClickable={isClickable}>
                            <ul className="portal__header__menu__pc--wrapper">
                                <li>
                                    <Link to="/portal">TOP</Link>
                                </li>
                                <li>
                                    <a
                                        id="nft-mall-btn"
                                        href="/portal#nft-mall"
                                    >
                                        NFTモール
                                    </a>
                                </li>
                                <li>
                                    <a
                                        id="home-special-btn"
                                        href="/portal#home-special"
                                    >
                                        Special
                                    </a>
                                </li>
                                <li>
                                    <a href="/portal#creator-list">
                                        クリエイター一覧
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href={`${process.env.REACT_APP_EC_API_URL}${process.env.REACT_APP_POINT_PRODUCTS}`}
                                    >
                                        ポイント購入
                                    </a>
                                </li>
                                <li>
                                    <Link to="/portal/favorites">
                                        お気に入り
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/portal/guide/user">
                                        使い方ガイド
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/portal/faq">FAQ</Link>
                                </li>
                                <li>
                                    <div
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={() => handleRedirectUrl()}
                                        onClick={() => handleRedirectUrl()}
                                    >
                                        マイページ
                                    </div>
                                </li>
                                <li>
                                    <a
                                        href={`${process.env.REACT_APP_EC_API_URL}/entry/artist`}
                                    >
                                        クリエイター申請
                                    </a>
                                </li>
                            </ul>
                        </ClickableWrapper>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.defaultProps = {
    headerStyles: null,
    headerWrapperStyles: null,
    isClickable: true,
}

export default Header
