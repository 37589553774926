import React from 'react'
import PencilIcon from '../../../assets/images/pencilIcon.png'

type Props = {
    handleEdit: () => void
    className?: string
}

const CreatorEditButton = (props: Props): JSX.Element => {
    const { handleEdit, className } = props
    return (
        <button type="button" onClick={handleEdit} className={className}>
            <img src={PencilIcon} />
        </button>
    )
}

CreatorEditButton.defaultProps = {
    className: null,
}

export default CreatorEditButton
