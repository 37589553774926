import React from 'react'
import { Button, UploadProps, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { Controller, useFormContext } from 'react-hook-form'

type Props = {
    label?: string
    name?: string
    value?: number
    className?: string
} & UploadProps

const InputFile = (props: Props): JSX.Element => {
    const { label, name, value, className, onChange, ...rest } = props
    const { control } = useFormContext() ?? {}

    return (
        <div className={className}>
            <p>{label}</p>
            {control && name ? (
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                        <Upload {...rest} {...field}>
                            <Button type="default" icon={<UploadOutlined />} />
                        </Upload>
                    )}
                />
            ) : (
                <Upload {...rest}>
                    <Button type="default" icon={<UploadOutlined />} />
                </Upload>
            )}
        </div>
    )
}

InputFile.defaultProps = {
    label: null,
    value: '',
    className: '',
    name: null,
}

export default InputFile
