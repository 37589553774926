import { map, orderBy } from 'lodash'
import qs from 'qs'
import CmsService from '../cms.service'
import { CarouselType } from '../../type/CarouselType'
import { GachaCmsType } from '../../type/GachaCmsType'
import { formatKeys } from '../../utils/filters'
import { QueryParams } from '../../type/QueryParams'

class GachaCmsService extends CmsService {
    public getCarousel() {
        return new Promise<Array<CarouselType>>((resolve, reject) => {
            this.client
                .get('gacha-list')
                .then((response) => {
                    const data = map(response.data.gacha_carousels, (item) => {
                        return formatKeys(item)
                    })
                    const currentTime = new Date().toISOString()

                    // filter data between startAt and endAt
                    const availableData = data.filter((item) => {
                        if (item.startAt && item.endAt) {
                            return (
                                currentTime >= item.startAt &&
                                currentTime <= item.endAt
                            )
                        }

                        if (item.startAt) {
                            return currentTime >= item.startAt
                        }

                        if (item.endAt) {
                            return currentTime <= item.endAt
                        }

                        return true
                    })

                    resolve(
                        // sort data by id asc
                        orderBy(
                            availableData,
                            (item) => item.id,
                            'asc'
                        ) as Array<CarouselType>
                    )
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public get(params: QueryParams) {
        return new Promise<Array<GachaCmsType>>((resolve, reject) => {
            this.client
                .get('gachas', {
                    params,
                })
                .then((response) => {
                    const gachas = response.data.map((item: GachaCmsType) => {
                        return {
                            ...item,
                            url: `/gacha/${item.id}`,
                        }
                    })
                    resolve(gachas)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getById(id: string) {
        return new Promise<GachaCmsType>((resolve, reject) => {
            this.client
                .get(`gachas/${id}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public count() {
        return new Promise<number>((resolve, reject) => {
            this.client
                .get(`gachas/count`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new GachaCmsService()
