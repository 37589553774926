import { useState } from 'react'

export type ModalState = {
    [m: string]: boolean
}

export type useModalStateType<T extends ModalState> = {
    modalState: T
    open: (m: keyof T) => void
    close: () => void
}

export function useModalState<T extends ModalState>(
    defaultModalState: T
): useModalStateType<T> {
    const [modalState, setModalState] = useState<T>(defaultModalState)

    const open = (m: keyof T) => {
        setModalState({
            ...defaultModalState,
            [m]: true,
        })
    }

    const close = () => {
        setModalState(defaultModalState)
    }

    return { modalState, open, close }
}
