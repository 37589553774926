import React from 'react'
import { isMobile } from 'react-device-detect'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../hook/creator/useCreatorSettingColor'
import {
    CREATOR_PREVIEW_IMG_KEYS,
    PreviewImagesState,
} from '../../../hook/creator/useCreatorSettingPreviewImg'
import { CreatorBackgroundType, CreatorType } from '../../../type/CreatorType'
import { getBackgroundCss } from '../../../utils/creatorUtil'

type Props = {
    children: React.ReactNode
    creator?: CreatorType
    settingColor?: CreatorSettingColorType
    previewImages?: PreviewImagesState
    backgroundType?: CreatorBackgroundType
    style?: React.CSSProperties
}

const CreatorComponent = (props: Props): JSX.Element => {
    const {
        children,
        style,
        settingColor,
        backgroundType,
        previewImages,
        creator,
    } = props

    const customStyles =
        creator && settingColor && backgroundType && previewImages
            ? {
                  color: `${
                      settingColor[COLOR_KEYS.COLOR_TEXT_GENERAL_KEY].hex
                  }`,
                  background: getBackgroundCss(
                      backgroundType,
                      settingColor?.[COLOR_KEYS.COLOR_BACKGROUND_PAGE_KEY]?.hex,
                      previewImages[
                          CREATOR_PREVIEW_IMG_KEYS.BACKGROUND_PREVIEW_KEY
                      ]?.metaData?.url ?? creator?.backgroundImage?.url
                  ),
              }
            : {}

    return (
        <section
            className="portal-creator"
            style={{
                paddingTop: isMobile ? '80px' : '115px',
                ...customStyles,
                ...style,
            }}
        >
            <div className="portal-creator__container">{children}</div>
        </section>
    )
}

CreatorComponent.defaultProps = {
    style: {},
    creator: undefined,
    settingColor: undefined,
    previewImages: undefined,
    backgroundType: undefined,
}

export default CreatorComponent
