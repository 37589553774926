import React from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks/AppHooks'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../../hook/creator/useCreatorSettingColor'

type newsIndexItemProps = {
    id: number
    title: string
    publishAt: string
    currentPage: number
    transitionSource: string
    url?: string
    settingColor: CreatorSettingColorType
}

function NewsIndexItem({
    id,
    title,
    publishAt,
    currentPage,
    transitionSource,
    url,
    settingColor,
}: newsIndexItemProps): JSX.Element {
    const groupCmsIdCurrent = useAppSelector(
        (state) => state.artistGroup.currentGroupCmsId
    )

    return (
        <div
            className="portal-news__index__list__item"
            style={{
                borderBottom: `2px dashed ${
                    settingColor?.[COLOR_KEYS.COLOR_TEXT_GENERAL_KEY]?.hex ?? ''
                }`,
            }}
        >
            <Link
                to={{
                    pathname: url ?? `/groups/${groupCmsIdCurrent}/news/${id}`,
                    state: { currentPage, transitionSource },
                }}
            >
                <div className="portal-news__index__list__item__container">
                    <div className="portal-news__index__list__item__title">
                        <p>{title}</p>
                    </div>
                    <div className="portal-news__index__list__item__publish-at">
                        <span>{publishAt}</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}
NewsIndexItem.defaultProps = {
    url: '',
}
export default NewsIndexItem
