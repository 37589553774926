import React from 'react'
import { ModalProps } from 'antd'
import { useTranslation } from 'react-i18next'
import BaseModal from '../BaseModal'

type Props = {
    children: React.ReactNode
    className?: string
} & ModalProps

const BaseModalSettingCreator = (props: Props): JSX.Element => {
    const { children, className, ...rest } = props
    const { t } = useTranslation()

    return (
        <BaseModal
            className={`base-modal__setting-creator-modal ${className}`}
            okText={t('label.common.save')}
            {...rest}
        >
            {children}
        </BaseModal>
    )
}

BaseModalSettingCreator.defaultProps = {
    className: null,
}

export default BaseModalSettingCreator
