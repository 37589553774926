import { useEffect, useState } from 'react'
import {
    CreatorBackgroundType,
    CreatorSettingLinkType,
    CreatorSettingPageHookType,
    CreatorSettingSnsType,
    CreatorType,
} from '../../type/CreatorType'
import { BG_TYPE_COLOR } from '../../utils/constant'

export type snsKeyType = 'twitter' | 'instagram' | 'tiktok' | 'facebook'
export const SNS_KEYS = {
    TWITTER: 'twitter' as const,
    INSTAGRAM: 'instagram' as const,
    TIKTOK: 'tiktok' as const,
    FACEBOOK: 'facebook' as const,
} as const

function useCreatorSettingPage(
    creator: CreatorType | null
): CreatorSettingPageHookType {
    const [creatorProfile, setCreatorProfile] = useState<string>('')
    const [snsInfo, setSnsInfo] = useState<CreatorSettingSnsType>({
        [SNS_KEYS.TWITTER]: creator?.[SNS_KEYS.TWITTER] ?? '',
        [SNS_KEYS.INSTAGRAM]: creator?.[SNS_KEYS.INSTAGRAM] ?? '',
        [SNS_KEYS.TIKTOK]: creator?.[SNS_KEYS.TIKTOK] ?? '',
        [SNS_KEYS.FACEBOOK]: creator?.[SNS_KEYS.FACEBOOK] ?? '',
    })
    const [links, setLinks] = useState<CreatorSettingLinkType[]>([])
    const [backgroundType, setBackgroundType] =
        useState<CreatorBackgroundType>(BG_TYPE_COLOR)
    const [isChangeDataSetting, setIsChangeDataSetting] =
        useState<boolean>(false)
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

    useEffect(() => {
        if (!creator) return

        setCreatorProfile(creator.profile ?? '')
        setSnsInfo({
            [SNS_KEYS.TWITTER]: creator?.[SNS_KEYS.TWITTER] ?? '',
            [SNS_KEYS.INSTAGRAM]: creator?.[SNS_KEYS.INSTAGRAM] ?? '',
            [SNS_KEYS.TIKTOK]: creator?.[SNS_KEYS.TIKTOK] ?? '',
            [SNS_KEYS.FACEBOOK]: creator?.[SNS_KEYS.FACEBOOK] ?? '',
        })
        setLinks(
            creator?.artistLinks?.length
                ? creator?.artistLinks?.map((link) => ({
                      id: link.id,
                      title: link.title,
                      url: link.url,
                  }))
                : [{ id: null, title: '', url: '' }]
        )
        setBackgroundType(creator?.backgroundType ?? BG_TYPE_COLOR)
    }, [creator])

    useEffect(() => {
        const handleUnload = (event: BeforeUnloadEvent) => {
            if (isChangeDataSetting) {
                event.preventDefault()
                // eslint-disable-next-line no-param-reassign
                event.returnValue = ''
            }
        }

        if (isChangeDataSetting) {
            window.addEventListener('beforeunload', handleUnload)

            window.onbeforeunload = () => {
                return ''
            }
        } else {
            window.removeEventListener('beforeunload', handleUnload)
            window.onbeforeunload = null
        }

        return () => {
            window.removeEventListener('beforeunload', handleUnload)
            window.onbeforeunload = null
        }
    }, [isChangeDataSetting])

    return {
        creatorProfile,
        snsInfo,
        links,
        backgroundType,
        isChangeDataSetting,
        isLoadingSubmit,
        setCreatorProfile,
        setSnsInfo,
        setLinks,
        setBackgroundType,
        setIsChangeDataSetting,
        setIsLoadingSubmit,
    }
}

export default useCreatorSettingPage
