import award1 from '../assets/images/portal/image_event/award1 1.png'
import award2 from '../assets/images/portal/image_event/award2 1.png'
import award3 from '../assets/images/portal/image_event/award3 1.png'

const EventStatus = () => {
    const status = {
        beforeAnnouncement: '1',
        notifying: '2',
        inProgress: '3',
        end: '4',
        NowAcceptingEntries: '5',
        award1: 1,
        award2: 2,
        award3: 3,
        selectByDay: 2,
        selectByMonth: 1,
        notShowFanRanking: '0',
        sortTimeByDay: 'day',
        sortTimeByMonth: 'month',
        periodHasEnded: 408,
        defaultSearch: 0,
    }
    return status
}
export default EventStatus

export const customFormatPoint = (point: any): string => {
    let newPoint: any
    if (!point) {
        newPoint = `0 PT`
    } else {
        newPoint = `${new Intl.NumberFormat().format(point)} PT`
    }
    return newPoint
}

export const renderAward = (index: number): string => {
    let image: any
    if (index === 0) {
        image = award1
    }
    if (index === 1) {
        image = award2
    }
    if (index === 2) {
        image = award3
    }
    return image
}

export const LIST_IS_ACTIVE_TRUE = ['3', '5']
export const LIST_IS_CHECK_ENTRY_TRUE = ['3', '4', '5', '6']
export const LIST_IS_CHECK_EVENT_TRUE = ['3', '5']
export const LIST_IS_CHECK_PICK_UP = ['3', '4']
export const LIST_IS_PAGE_NOT_FOUND = ['1']
export const LIST_SHOW_BUTTON_JOIN = ['2', '5']
export const MAX_LENGTH_BONUS_OTHER_CONTENT = 30
export const MAX_LENGTH_BONUS_AWARD_NAME = 55

export const LOCAL_STORAGE_KEY_EVENT_CODE = 'event_code'
export const LOCAL_STORAGE_KEY_NFT_ID = 'nft_id'

export const LIMIT_LIST_ARTIST_HOME = 6
export const LIMIT_LIST_CREATOR = 12
export const LIMIT_LIST_MEMBER = 8
export const LIMIT_LIST_BLOG = 4
export const LIMIT_LIST_NEWS = 4
export const LIMIT_SHOW_NFT = 2
export const LIMIT_LIST_LIVE = 2
export const LIMIT_LIST_PERSONAL_TALK = 2
export const LIMIT_LIST_SHOP = 2

export const NUMBER_IMAGE_SLIDER_PC = 3
export const NUMBER_IMAGE_SLIDER_SP = 2
export const SCREEN_MOBILE_XL = 568

export const PIALA_AUTH_TOKEN = 'pialaAuthToken'

export const ACCEPT_UPLOAD_CREATOR_SETTING =
    'image/png, image/jpeg, image/jpg, image/webp'
export const ACCEPT_UPLOAD_VIDEO_CREATOR_SETTING =
    'video/mp4, video/webm, video/x-matroska, video/quicktime'
export const ACCEPT_UPLOAD_CREATOR_SETTING_GALLERY =
    'image/png, image/jpeg, image/jpg, image/webp, video/mp4, video/webm, video/x-matroska, video/quicktime'

export const MODAL_EDIT_SETTING_CREATOR_PROFILE =
    'modalEditSettingCreatorProfile'
export const MODAL_EDIT_SETTING_CREATOR_LINK = 'modalEditSettingCreatorLink'
export const MODAL_EDIT_SETTING_CREATOR_NEW = 'modalEditSettingCreatorNew'
export const MODAL_EDIT_SETTING_CREATOR_MEMBER = 'modalEditSettingCreatorMember'
export const MODAL_EDIT_SETTING_CREATOR_NFT = 'modalEditSettingCreatorNFT'
export const MODAL_EDIT_SETTING_CREATOR_LIVE = 'modalEditSettingCreatorLive'
export const MODAL_EDIT_SETTING_CREATOR_SHOP = 'modalEditSettingCreatorShop'
export const MODAL_EDIT_SETTING_CREATOR_PERSONAL_TALK =
    'modalEditSettingCreatorPersonalTalk'
export const MODAL_EDIT_SETTING_CREATOR_GENERAL_SETTING =
    'modalEditSettingCreatorGenerateSetting'
export const MODAL_EDIT_SETTING_CREATOR_AVATAR = 'modalEditSettingCreatorAvatar'
export const MODAL_EDIT_SETTING_CREATOR_SNS = 'modalEditSettingCreatorSns'
export const MODAL_EDIT_SETTING_CREATOR_GENERAL_COLOR_SETTING =
    'modalEditSettingCreatorGenerateColorSetting'
export const MODAL_EDIT_SETTING_CREATOR_BANNER = 'modalEditSettingCreatorBanner'
export const MODAL_EDIT_SETTING_CREATOR_GALLERY =
    'modalEditSettingCreatorGallery'
export const MODAL_EDIT_SETTING_CREATOR_HEADER = 'modalEditSettingCreatorHeader'
export const MODAL_EDIT_SETTING_CREATOR_FOOTER = 'modalEditSettingCreatorFooter'
export const MODAL_EDIT_SETTING_CREATOR_CLUB = 'modalEditSettingCreatorClub'
export const MODAL_EDIT_SETTING_CREATOR_BLOG = 'modalEditSettingCreatorBlog'
export const MODAL_EDIT_SETTING_CREATOR_BACKGROUND =
    'modalEditSettingCreatorBackground'
export const MODAL_EDIT_SETTING_CREATOR_GALLERY_CROP =
    'modalEditSettingCreatorGalleryCrop'

export const DEFAULT_TEXT_COLOR = '#000000'
export const DEFAULT_HEADER_COLOR = '#6f6f6f'
export const DEFAULT_HEADER_BG_COLOR = '#000000'
export const DEFAULT_FOOTER_COLOR = '#ffffff'
export const DEFAULT_FOOTER_BG_COLOR = '#000000'
export const DEFAULT_BG_COLOR = '#ffffff'

export const BG_TYPE_COLOR = 'color'
export const BG_TYPE_IMAGE = 'image'

export const LIVE_TYPE = {
    LIVE: 0,
    PERSONAL_TAKE: 1,
}

export const MAX_IMAGE_UPLOAD_SIZE = 5000000
export const MAX_VIDEO_UPLOAD_SIZE = 100000000

export const DEFAULT_NEWS_LIMIT = 10

export const BLOG_ID = 'blog'
export const LIVE_ID = 'live'
export const PERSONAL_TALK_ID = 'personal-talk'
export const SHOP_ID = 'shop'
export const NFT_ID = 'nft'
export const CLUB_ID = 'club'

export const LINK_TITLE_FIELD = 'title'
export const LINK_URL_FIELD = 'url'
