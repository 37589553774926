import { useEffect, useState } from 'react'
import { CreatorPreviewImage, CreatorType } from '../../type/CreatorType'
import { CardCropShowType } from '../useUpload'
import uploadFileService from '../../services/upload/uploadFile.service'
import { MediaType } from '../../type/MediaType'
import { useAppDispatch } from '../../store/hooks/AppHooks'
import { GlobalLoadingTypes } from '../../store/constant/global-loading.const'

export type KeyCreatorPreviewImg =
    | 'avatar'
    | 'banner'
    | 'galleryPreview'
    | 'backgroundImage'

export const CREATOR_PREVIEW_IMG_KEYS = {
    AVATAR_PREVIEW_KEY: 'avatar' as const,
    BANNER_PREVIEW_KEY: 'banner' as const,
    GALLERY_PREVIEW_KEY: 'galleryPreview' as const,
    BACKGROUND_PREVIEW_KEY: 'backgroundImage' as const,
} as const

type CreatorSettingPreviewImgHook = {
    previewImages: PreviewImagesState
    updatePreviewImage: (key: string, value: CreatorPreviewImage) => void
    updateMultiPreviewImage: (key: string, value: CardCropShowType[]) => void
    uploadFile: (files: File[], isImage: boolean) => Promise<MediaType[]>
}

export type PreviewImagesState = {
    [CREATOR_PREVIEW_IMG_KEYS.AVATAR_PREVIEW_KEY]:
        | CreatorPreviewImage
        | undefined
    [CREATOR_PREVIEW_IMG_KEYS.BANNER_PREVIEW_KEY]:
        | CreatorPreviewImage
        | undefined
    [CREATOR_PREVIEW_IMG_KEYS.GALLERY_PREVIEW_KEY]: CardCropShowType[]
    [CREATOR_PREVIEW_IMG_KEYS.BACKGROUND_PREVIEW_KEY]:
        | CreatorPreviewImage
        | undefined
}

const useCreatorSettingPreviewImg = (
    creatorCms: CreatorType | null
): CreatorSettingPreviewImgHook => {
    const dispatch = useAppDispatch()
    const [previewImages, setPreviewImages] = useState<PreviewImagesState>({
        [CREATOR_PREVIEW_IMG_KEYS.AVATAR_PREVIEW_KEY]: undefined,
        [CREATOR_PREVIEW_IMG_KEYS.BANNER_PREVIEW_KEY]: undefined,
        [CREATOR_PREVIEW_IMG_KEYS.GALLERY_PREVIEW_KEY]: [],
        [CREATOR_PREVIEW_IMG_KEYS.BACKGROUND_PREVIEW_KEY]: undefined,
    })

    const updatePreviewImage = (key: string, value: CreatorPreviewImage) => {
        setPreviewImages({
            ...previewImages,
            [key]: value,
        })
    }

    const updateMultiPreviewImage = (
        key: string,
        value: CardCropShowType[]
    ) => {
        setPreviewImages({
            ...previewImages,
            [key]: value,
        })
    }

    const uploadFile = async (files: File[], isImage: boolean) => {
        let data: MediaType[] = []
        dispatch({
            type: GlobalLoadingTypes.setGlobalLoading,
            payload: true,
        })
        await uploadFileService
            .uploadTemp(files, isImage)
            .then((res) => {
                data = res ?? []
            })
            .finally(() => {
                dispatch({
                    type: GlobalLoadingTypes.setGlobalLoading,
                    payload: false,
                })
            })

        return data
    }

    useEffect(() => {
        if (!creatorCms) return

        if (creatorCms?.artistGalleries) {
            const galleries = creatorCms?.artistGalleries?.sort(
                (a, b) => a.order - b.order
            )
            updateMultiPreviewImage(
                CREATOR_PREVIEW_IMG_KEYS.GALLERY_PREVIEW_KEY,
                galleries?.map((item, index) => {
                    const isVideo = item.image.mime.includes('video')
                    return {
                        id: `${item?.id}`,
                        url: item.image.url,
                        file: undefined,
                        fileName: item.image.name,
                        previewCanvasRef: undefined,
                        completedCrop: undefined,
                        isVideo,
                        metaData: null,
                    }
                })
            )
        }
    }, [creatorCms])

    return {
        previewImages,
        updatePreviewImage,
        updateMultiPreviewImage,
        uploadFile,
    }
}

export default useCreatorSettingPreviewImg
