import React from 'react'
import { Radio, RadioChangeEvent } from 'antd'

export type Item = {
    label: string
    value: boolean | string | number
}

type Props = {
    name: string
    items: Item[]
    defaultValue: boolean | string | number
    onChange: (e: RadioChangeEvent) => void
    className?: string
}

const RadioGroup = (props: Props): JSX.Element => {
    const { name, items, className, defaultValue, onChange } = props

    return (
        <Radio.Group
            defaultValue={defaultValue}
            options={items}
            className={className}
            name={name}
            onChange={onChange}
        />
    )
}

RadioGroup.defaultProps = {
    className: null,
}

export default RadioGroup
