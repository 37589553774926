import React, { Suspense } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Loading from '../components/portal/Loading'
import NewsList from '../components/portal/groups/news/NewsList'

const BlogComponent = React.lazy(
    () => import('../components/portal/blogs/Blogs')
)

const BlogDetailComponent = React.lazy(
    () => import('../components/portal/blogs/BlogDetail')
)

const LiveTicketComponent = React.lazy(
    () => import('../components/portal/liveTickets/LiveTickets')
)

const GroupNewsDetailComponent = React.lazy(
    () => import('../components/portal/groups/news/GroupNewsDetail')
)

const CreatorDetail = React.lazy(
    () => import('../components/portal/CreatorDetail')
)

function ArtistRoutes(): JSX.Element {
    const { path } = useRouteMatch()

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Route exact path={`${path}`} key="portal-artist">
                    <CreatorDetail isArtist />
                </Route>
                {/* <Route exact path={`${path}/old`} key="portal-artist">
                    <ArtistComponent />
                </Route> */}
                <Route exact path={`${path}/blog`} key="portal-artist__blog">
                    <BlogComponent isArtist />
                </Route>
                <Route
                    exact
                    path={`${path}/blog/:blogId`}
                    key="portal-artist__blog"
                >
                    <BlogDetailComponent isArtist />
                </Route>
                <Route
                    exact
                    path={`${path}/personal-talk`}
                    key="portal-artist__personal-talk"
                >
                    <LiveTicketComponent isArtist />
                </Route>
                <Route exact path={`${path}/live`} key="portal-artist__live">
                    <LiveTicketComponent isArtist />
                </Route>
                <Route exact path={`${path}/news`} key="portal-artist__news">
                    <NewsList isArtist />
                </Route>
                <Route
                    exact
                    path={`${path}/news/:newsId`}
                    key="portal-artist__news-detail"
                >
                    <GroupNewsDetailComponent isArtist />
                </Route>
            </Switch>
        </Suspense>
    )
}

export default ArtistRoutes
