import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SketchPicker, ColorResult } from 'react-color'
import BaseModalSettingCreator from './BaseModalSettingCreator'
import {
    COLOR_KEYS,
    CreatorSettingColorType,
} from '../../../../hook/creator/useCreatorSettingColor'
import { converRgbaToHex } from '../../../../utils/utils'

export type GeneralColorType = Pick<
    CreatorSettingColorType,
    | typeof COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY
    | typeof COLOR_KEYS.COLOR_TEXT_FOOTER_KEY
>

type Props = {
    onOk: (listSetting: GeneralColorType) => void
    onCancel: () => void
    isModalOpen: boolean
    colorBackground: CreatorSettingColorType
    colorText: CreatorSettingColorType
}

const ModalSettingFooter = (props: Props): JSX.Element => {
    const { onOk, onCancel, isModalOpen, colorBackground, colorText } = props
    const { t } = useTranslation()
    const [settingColorText, setSettingsColorText] = useState<GeneralColorType>(
        {
            [COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY]:
                colorBackground[COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY],
            [COLOR_KEYS.COLOR_TEXT_FOOTER_KEY]:
                colorText[COLOR_KEYS.COLOR_TEXT_FOOTER_KEY],
        }
    )

    const actionOk = () => {
        onOk(settingColorText)
    }

    const onChangeColor = (
        key:
            | typeof COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY
            | typeof COLOR_KEYS.COLOR_TEXT_FOOTER_KEY,
        color: ColorResult
    ) => {
        setSettingsColorText({
            ...settingColorText,
            [key]: {
                hex: converRgbaToHex(color.rgb),
                rgb: color.rgb,
            },
        })
    }

    return (
        <BaseModalSettingCreator
            onOk={actionOk}
            onCancel={onCancel}
            visible={isModalOpen}
            title={t('label.page.setting.footer_settings')}
            cancelButtonProps={{ style: { display: 'none' } }}
            className="modal-setting-color modal-setting-one-color"
        >
            {/* <div>{t('label.page.setting.footer_settings')}</div> */}
            <div className="box-setting-footer-modal">
                <div>
                    <div className="d-title-setting-footer-text font14">
                        {t('label.common.background_color')}
                    </div>
                    <div className="box-picker">
                        <SketchPicker
                            color={
                                settingColorText[
                                    COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY
                                ].rgb
                            }
                            onChange={(color) =>
                                onChangeColor(
                                    COLOR_KEYS.COLOR_BACKGROUND_FOOTER_KEY,
                                    color
                                )
                            }
                            width="271px"
                        />
                    </div>
                </div>
                <div>
                    <div className="d-title-setting-footer-text font14">
                        {t('label.common.text_color')}
                    </div>
                    <div className="box-picker">
                        <SketchPicker
                            color={
                                settingColorText[
                                    COLOR_KEYS.COLOR_TEXT_FOOTER_KEY
                                ].rgb
                            }
                            onChange={(color) =>
                                onChangeColor(
                                    COLOR_KEYS.COLOR_TEXT_FOOTER_KEY,
                                    color
                                )
                            }
                            width="271px"
                        />
                    </div>
                </div>
            </div>
        </BaseModalSettingCreator>
    )
}

export default ModalSettingFooter
