import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { message } from 'antd'
import { redirectLogin } from '../utils/utils'

function Protected(): JSX.Element {
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        message.warn(
            'このページにアクセスするためにログインする必要があります。'
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (
            location.pathname === '/portal/favorites' ||
            location.pathname === '/portal/setting-creator'
        ) {
            redirectLogin()
            return
        }
        history.push('/')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    return <></>
}

export default Protected
