import CmsService from '../cms.service'
import { NewsType } from '../../type/NewsType'
import { QueryParams } from '../../type/QueryParams'

class NewsService extends CmsService {
    public get(params: QueryParams) {
        const queryParams = CmsService.getNewsArticleParams(params)

        return new Promise<Array<NewsType>>((resolve, reject) => {
            this.client
                .get(`news-articles?${queryParams}`)
                .then((response) => {
                    const news = response.data.map((item: NewsType) => {
                        return {
                            ...item,
                            url: `/news/${item.id}`,
                        }
                    })
                    resolve(news)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getById(id: string, params: QueryParams = {}) {
        return new Promise<NewsType>((resolve, reject) => {
            const queryParams = CmsService.getNewsArticleParams(params)

            this.client
                .get(`news-articles/${id}?${queryParams}`, {
                    params,
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getCount() {
        const queryParams = CmsService.getNewsArticleParams()

        return new Promise<number>((resolve, reject) => {
            this.client
                .get(`news-articles/count?${queryParams}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }

    public getNewsBanner() {
        return new Promise<any>((resolve, reject) => {
            this.client
                .get('news-banners?_sort=id:DESC')
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    }
}

export default new NewsService()
