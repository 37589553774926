import React from 'react'
import { useTranslation } from 'react-i18next'
import { RadioChangeEvent } from 'antd'
import RadioGroup, { Item } from '../../form/inputs/RadioGroup'

type Props = {
    name: string
    defaultValue: boolean | string | number
    className?: string
    onChange: (e: RadioChangeEvent) => void
    items?: Item[]
}

const CreatorRadioGroup = (props: Props): JSX.Element => {
    const { name, defaultValue, className, items, onChange } = props
    const { t } = useTranslation()

    return (
        <RadioGroup
            className={`portal-creator__radio-group ${className}`}
            name={name}
            items={
                items?.length
                    ? items
                    : [
                          { label: t('label.common.display'), value: true },
                          { label: t('label.common.hidden'), value: false },
                      ]
            }
            defaultValue={defaultValue}
            onChange={onChange}
        />
    )
}

CreatorRadioGroup.defaultProps = {
    className: undefined,
    items: [],
}

export default CreatorRadioGroup
